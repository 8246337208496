<template>
  <div>
    <transition name="fade">
      <AcceptModal v-if="modalOpen" />
    </transition>

    <div class="box-body">
      <box-head
        :titleText="title"
        :descriptionText="description"
      />

      <div v-if="connectionReady" class="box-content">

        <AuthTypeChoose />

        <Telegram v-if="selectedAuthType && selectedAuthType.value === showTelegram" />
        <Biometrical v-if="selectedAuthType && selectedAuthType.value === showWebAuth" />
        <HotpToken v-if="selectedAuthType && selectedAuthType.value === showHotpToken" />
        <TotpToken v-if="selectedAuthType && selectedAuthType.value === showTotpToken" />
        <Sms v-if="selectedAuthType && selectedAuthType.value === showSms" />
        <MobileApp v-if="selectedAuthType && selectedAuthType.value === showApp" :method="selectedAuthType.preferredMethod" />

      </div>
    </div>
    
    <AnimationBtn :active="animateActive" />

    <HiddenFrom v-if="hiddenFormShow" />

  </div>
</template>

<script>
  import BoxHead from '../components/BoxHead.vue';
  import AnimationBtn from '../components/AnimationBtn.vue';
  import AcceptModal from '../components/modals/AcceptModal.vue';
  import {  VALIDATION_TYPE_TELEGRAM,
            VALIDATION_TYPE_WEBAUTHN,
            VALIDATION_TYPE_HOTPTOKEN,
            VALIDATION_TYPE_TOTPTOKEN,
            VALIDATION_TYPE_SMS,
            VALIDATION_TYPE_APP,
          } from '../config';
  import HiddenFrom from '../components/HiddenForm.vue';

  // Authorizations components
  import AuthTypeChoose from '../components/auth-types-validations/AuthTypeChoose.vue';
  import Telegram from '../components/auth-types-validations/Telegram.vue';
  import Biometrical from '../components/auth-types-validations/Biometrical.vue';
  import TotpToken from '../components/auth-types-validations/TotpToken.vue';
  import MobileApp from '../components/auth-types-validations/MobileApp.vue';
  import HotpToken from '../components/auth-types-validations/HotpToken.vue';
  import Sms from '../components/auth-types-validations/Sms.vue';
  import { eventBus, EMIT_NO_AUTH_PROVIDERS } from '../event-bus.js';
  import { REQUEST_ID } from '../config.js';

  export default {
    components: {
      BoxHead,
      AuthTypeChoose,
      Telegram,
      Biometrical,
      TotpToken,
      MobileApp,
      HotpToken,
      Sms,
      AnimationBtn,
      AcceptModal,
      HiddenFrom
    },
    data() {
      return {
        title: this.$i18n.t('authPage.title'),
        description: this.$i18n.t('authPage.description'),
        modalOpen: false,

        showTelegram: VALIDATION_TYPE_TELEGRAM,
        showWebAuth: VALIDATION_TYPE_WEBAUTHN,
        showHotpToken: VALIDATION_TYPE_HOTPTOKEN,
        showTotpToken: VALIDATION_TYPE_TOTPTOKEN,
        showSms: VALIDATION_TYPE_SMS,
        showApp: VALIDATION_TYPE_APP,
      }
    },
    computed: {
      selectedAuthType() {
        return this.$store.getters.GET_AUTH_TYPE;
      },
      animateActive() {
        return this.$store.getters.GET_ANIMATE_BUTTON;
      },
      hiddenFormShow() {
        return this.$store.getters.GET_HIDDEN_FORM_SHOW;
      },
      connectionReady() {
        return this.$store.getters.GET_CONNECTION_READY;
      }
    },
    mounted() {
      eventBus.$on('modal-open', modalOpen => {
        this.modalOpen = modalOpen;
      });

      eventBus.$on(EMIT_NO_AUTH_PROVIDERS, () => {
        document.location.href = `/wizard/${REQUEST_ID}`;
      });
    }
  }
</script>

