<template>
  <div class="box-body">
    <div class="btn-freresh-holder">
      <div v-if="webAuthAllowed">
        <button v-if="showRefresh" class="refresh-button style" @click.stop.prevent="makeAssertionOptions()" ref="rotateButton">
          <i class="el-icon-refresh"></i>
        </button>
      </div>
      <div v-else>
        {{ $t('validation.bio') }}
      </div>
    </div>

  </div>
</template>

<script>
  import Api from '../../services/api.js';
  import { CONNECTION } from '../../services/hub.js';
  import { REQUEST_ID, VALIDATION_TYPE_WEBAUTHN } from '../../config.js';
  
  export default {
    data() {
      return {
        showRefresh: false,
        webAuthAllowed: true,
      }
    },
    computed: {},
    mounted() {
      this.$store.dispatch('SET_ANIMATE_BUTTON', true);
      this.detectFIDOSupport();
      if(this.webAuthAllowed) {
        this.$store.commit('MUTATE_AUTH_TYPE_SELECTED', null);
        this.makeAssertionOptions();
      }
    },
    methods: {
      detectFIDOSupport() {
        if (window.PublicKeyCredential === undefined || typeof window.PublicKeyCredential !== "function") {
          this.webAuthAllowed = false;
        }
      },
      coerceToArrayBuffer (thing, name) {
        if (typeof thing === "string") {
          thing = thing.replace(/-/g, "+").replace(/_/g, "/");

          // base64 to Uint8Array
          let str = window.atob(thing);
          let bytes = new Uint8Array(str.length);
          for (var i = 0; i < str.length; i++) {
            bytes[i] = str.charCodeAt(i);
          }
          thing = bytes;
        }

        // Array to Uint8Array
        if (Array.isArray(thing)) {
          thing = new Uint8Array(thing);
        }

        // Uint8Array to ArrayBuffer
        if (thing instanceof Uint8Array) {
          thing = thing.buffer;
        }

        // error if none of the above worked
        if (!(thing instanceof ArrayBuffer)) {
          throw new TypeError("could not coerce '" + name + "' to ArrayBuffer");
        }

        return thing;
      },
      coerceToBase64Url (thing) {
        // Array or ArrayBuffer to Uint8Array
        if (Array.isArray(thing)) {
          thing = Uint8Array.from(thing);
        }

        if (thing instanceof ArrayBuffer) {
          thing = new Uint8Array(thing);
        }

        // Uint8Array to base64
        if (thing instanceof Uint8Array) {
          let str = "";
          let len = thing.byteLength;

          for (let i = 0; i < len; i++) {
            str += String.fromCharCode(thing[i]);
          }
          thing = window.btoa(str);
        }

        if (typeof thing !== "string") {
          throw new Error("could not coerce to string");
        }

        // base64 to base64url
        // NOTE: "=" at the end of challenge is optional, strip it off here
        thing = thing
          .replace(/\+/g, "-")
          .replace(/\//g, "_")
          .replace(/=*$/g, "");

        return thing;
      },
      makeAssertion (assertedCredential) {
        // Move data into Arrays incase it is super long
        let authData = new Uint8Array(
          assertedCredential.response.authenticatorData
        );
        let clientDataJSON = new Uint8Array(
          assertedCredential.response.clientDataJSON
        );
        let rawId = new Uint8Array(assertedCredential.rawId);
        let sig = new Uint8Array(assertedCredential.response.signature);

        CONNECTION.invoke("Auth", REQUEST_ID, {
          Provider: VALIDATION_TYPE_WEBAUTHN,
          Webauthn: {
            id: assertedCredential.id,
            rawId: this.coerceToBase64Url(rawId),
            type: assertedCredential.type,
            extensions: assertedCredential.getClientExtensionResults(),
            response: {
              authenticatorData: this.coerceToBase64Url(authData),
              clientDataJson: this.coerceToBase64Url(clientDataJSON),
              signature: this.coerceToBase64Url(sig)
            }
          }
        }).catch(err => {
          console.error(err.toString());
        });
      },
      makeAssertionOptions () {
        Api.getWebAuthResponse().then((response) => {

          if (!response.status === 200) {
            console.error("Error creating assertion options", response.message);
            return;
          }

          let options = response.data.model;

          options.challenge = this.coerceToArrayBuffer(options.challenge);
          options.allowCredentials = options.allowCredentials.map(c => {
            c.id = this.coerceToArrayBuffer(c.id);
            return c;
          });

          // remove for production
          // options.rpId = 'localhost';

          navigator.credentials
            .get({ publicKey: options })
            .then(credential => {
              this.makeAssertion(credential);
            })
            .catch(err => {
              this.showRefresh = true;
              console.error(err);
            });
        });
      },

      rotate() {
        this.$refs.rotateButton.classList.add('rotate');
        setTimeout(() => {
          this.$refs.rotateButton.classList.remove('rotate');
          this.$refs.rotateButton.classList.add('sended');
          setTimeout(() => {
            this.$refs.rotateButton.classList.remove('sended');
            setTimeout(() => {
                this.showRefresh = false;
            }, 0);
            setTimeout(() => {
              this.showRefresh = true;
            }, 30000);
          }, 2000);
        }, 360);
      },
    },
    destroyed() {
      this.$store.dispatch('SET_ANIMATE_BUTTON', false);
    }
  }
</script>