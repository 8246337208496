<template>
  <div class="head">
    <div class="top">
      <div v-if="showExclamationMark" class="exclamation">
        <img src="@/assets/images/exclamation_mark.svg" alt="exclamation" />
      </div>
      <div v-if="showBackArrow">
        <div class="back-btn-container">
          <div class="back-btn-box" v-on:click.prevent="back()">
            <img src="@/assets/images/back_arr.svg" />
            <span class="back-text">{{ $t('headLinks.back') }}</span>
          </div>
          <div v-if="showDropdown" class="drop-container">
            <el-dropdown v-if="showDropdown" :class="{ 'active': activeDrop }" @visible-change="checkActiveDrop()"
              trigger="click">
              <span class="el-dropdown-link">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <a href="#" @click.prevent="openModal()">{{ $t('headLinks.auth') }}</a>
                </el-dropdown-item>
                <el-dropdown-item>
                  <a :href="HELP_LINK" target="_blank">{{ $t('headLinks.help') }}</a>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link :to="`/${REQUEST_ID}/contacts`" tag="span">{{ $t('headLinks.contacts') }}</router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="title-container">
          <h2 v-html="titleText"></h2>
        </div>
      </div>
      <div v-else>
        <div class="title-drop-container">
          <div class="title-container">
            <h2 v-html="titleText"></h2>
          </div>
          <div v-if="showDropdown" class="drop-container">
            <el-dropdown v-if="showDropdown" :class="{ 'active': activeDrop }" @visible-change="checkActiveDrop()"
              trigger="click">
              <span class="el-dropdown-link">
                <i class="el-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <a href="#" @click.prevent="openModal()">{{ $t('headLinks.auth') }}</a>
                </el-dropdown-item>
                <el-dropdown-item>
                  <a :href="HELP_LINK" target="_blank">{{ $t('headLinks.help') }}</a>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link :to="`/${REQUEST_ID}/contacts`" tag="span">{{ $t('headLinks.contacts') }}</router-link>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
    <p v-if="showDescription" v-html="descriptionText"></p>
  </div>
</template>

<script>
import { eventBus } from '../event-bus.js';
import { HELP_LINK, REQUEST_ID } from '../config.js';
export default {
  props: {
    showDropdown: {
      type: Boolean,
      default: true,
    },
    showBackArrow: {
      type: Boolean,
      default: false,
    },
    showExclamationMark: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    titleText: {
      type: String,
      default: null,
    },
    showDescription: {
      type: Boolean,
      default: true,
    },
    descriptionText: {
      type: String,
      default: ''
    },
    showLink: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      modalOpen: false,
      activeDrop: false,
      HELP_LINK,
      REQUEST_ID,
    }
  },
  methods: {
    back() {
      this.$router.push({ name: 'Home', params: { pathMatch: this.$route.params.pathMatch } });
    },
    openModal() {
      this.modalOpen = true;
      eventBus.$emit('modal-open', this.modalOpen);

      this.$store.dispatch('SET_CLICKED_LINK', true);
    },
    checkActiveDrop() {
      this.activeDrop = !this.activeDrop;
    }
  },
  mounted() {
    eventBus.$on('modal-open', (modalOpen) => {
      this.modalOpen = modalOpen;
    });
  }
}
</script>

<style scoped lang="scss">
.exclamation {
  margin-bottom: 16px;
}

p {
  margin: 0 0 10px;
  line-height: 20.25px;
}

.head {
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.back-text {
  color: $blue;
  font-size: 16px;
  font-weight: 600;
  margin-left: 8px;
}

.back-btn-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: space-between;
}

.back-btn-box {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.back-drop-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title-drop-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-container {
  display: flex;
}

.back-arrow {
  width: 20px;
  height: 14px;
  background-image: url('../assets/images/back_arr.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.top {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 13px;
  position: relative;
  width: 100%;

  &.style {
    padding-left: 32px;
  }
}

h2 {
  margin-right: 3px;
  flex: 1;
}

.link {
  margin-right: 3px;
  margin-top: 8px;
  border: none;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: #31628e;
  }
}

::v-deep .el-dropdown {

  &.active {
    .el-dropdown-link {
      color: $blue;
    }
  }

  &-link {
    transform: rotate(90deg);
    display: block;
    font-size: 20px;
    color: $black;
    padding: 10px;
    cursor: pointer;
    transition: all .35s ease 0s;

    &:hover {
      color: $blue;
    }
  }
}

.el-dropdown-menu {
  margin: 0 !important;
  padding: 0;
  border: none;
  border-radius: 4px;

  &__item {
    line-height: 1;
    color: $black;
    padding: 0;

    &:focus,
    &:hover {
      color: $black;
      background: $hoverBg;
    }
  }

  a,
  span {
    padding: 0 20px;
    line-height: 46px;
    display: block;
    text-decoration: none;
  }

  &::v-deep {
    .popper__arrow {
      display: none !important;
    }
  }
}

.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  color: $black;
  background: $hoverBg;
}
</style>