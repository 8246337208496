<template>
  <div>
    <div class="form-row" :class="{'active': selectedValue()}" ref="controlRow">
      <div
        class="el-form-item"
        ref="formItem">
        <div class="form-control-plh">
          {{plh}}
        </div>
        <el-input
          type="tel"
          oninput="this.value = this.value.replace(/[^0-9]/g, '')"
          ref="inputField"
          name="smscode"
          class="form-control with-btn"
          v-model="codeInput"
          :maxlength="validationLength"
          @focus="focusInput()"
          @blur="blurInput()"
          @input="submitForm()">
        </el-input>
        <button
          v-if="showRefresh"
          class="refresh-button"
          @click.stop.prevent="rotate()"
          ref="rotateButton">
          <i class="el-icon-refresh"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  
  export default {
    
    data() {
      return {
        plh: this.$i18n.t('controls.sms.plh'),
      }
    },
    computed: {
      codeInput: {
        get() {
          return this.$store.getters.GET_CODE;
        },
        set(v) {
          this.$store.commit('MUTATE_CODE', v);
        }
      },
      validationLength() {
        return this.$store.getters.GET_CODE_VALIDATION_LENGTH;
      },
      showRefresh() {
        return this.$store.getters.GET_SHOW_REFRESH_CODE;
      }
    },
    methods: {
      rotate() {
        this.$emit('resendSmsmCode');
        this.$refs.rotateButton.classList.add('rotate');
        if (this.codeInput && this.codeInput.length === this.validationLength) {
          this.$store.dispatch('SET_CODE', this.codeInput);
        }
        setTimeout(() => {
          this.$refs.rotateButton.classList.remove('rotate');
          this.$refs.rotateButton.classList.add('sended');
          setTimeout(() => {
            this.$refs.rotateButton.classList.remove('sended');
            setTimeout(() => {
                this.$store.commit('MUTATE_SHOW_RESEND_CODE', false);
            }, 0);
          }, 2000);
        }, 360);
      },
      selectedValue() {
        return !!this.codeInput;
      },
      focusInput() {
        this.$store.dispatch('SET_ANIMATE_BUTTON', true);
        this.$refs.controlRow.classList.add('active');
        this.$refs.formItem.classList.remove('is-error');
      },
      blurInput() {
        this.$store.dispatch('SET_ANIMATE_BUTTON', false);
        if(!this.codeInput) {
          this.$refs.controlRow.classList.remove('active');
          this.$refs.formItem.classList.add('is-error');
        } else if (this.codeInput.length < this.validationLength) {
          this.$refs.formItem.classList.add('is-error');
        }
      },
      submitForm() { 
        if (this.codeInput && this.codeInput.length === this.validationLength) {
          this.$store.dispatch('SET_CODE', this.codeInput);
        }
      }
    },
    mounted() {
      this.$refs.inputField.focus();
      this.codeInput = this.$store.getters.GET_CODE;
      if(this.codeInput) {
        this.focusInput();
      }
      this.selectedValue();
    },
  }
</script>