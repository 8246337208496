<template>
  <div class="form-row"
    :class="{'active': selectedClass}"
   ref="controlRow">
    <div class="select-box">
      <div class="form-control-plh">
        {{plh}}
      </div>
       <el-select 
        v-model="selectedValue"
        placeholder=""
        :popper-append-to-body="false">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
  import Api from '../../services/api.js';

  export default {
    data() {
      return {
        plh: this.$i18n.t('validation.telegram'),
        options: [],
        disabled: false,
      }
    },
    computed: {
      selectedValue: {
        get() {
          return this.$store.getters.GET_AUTH_TYPE_SELECTED_VALUE;
        },
        set(v) {
          this.$store.dispatch('SET_ANIMATE_BUTTON', true);
          this.$store.commit('MUTATE_AUTH_TYPE_SELECTED', v);
          this.$refs.controlRow.classList.add('active');
          this.$store.dispatch('SEND_TOKEN');
        }
      },
      selectedClass() {
        return this.selectedValue;
      },
    },
    mounted() {
      Api.getTelegramTypes().then((response) => {
        this.options = response.data;
        if (this.options.length === 1) {
          this.selectedValue = this.options[0].value;
        }
      });
    },
    destroyed() {
      this.$store.dispatch('SET_ANIMATE_BUTTON', false);
    }
  }
</script>