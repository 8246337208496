<template>
  <div>
    <form @submit.prevent="handleSubmit">
      <div class="form-row" :class="{'active': isActive}" ref="controlRow">
        <div
          class="el-form-item"
          ref="formItem">
          <div class="form-control-plh">
            {{plh}}
          </div>
          
          <el-input v-if="digitsOnly"
            type="tel"
            oninput="this.value = this.value.replace(/[^0-9]/g, '')"
            ref="inputField"
            name="otpcode"
            class="form-control with-btn"
            v-model="codeInput"
            :maxlength="getMaxLength"
            @focus="focusInput()"
            @blur="blurInput()"
            @input="handleInput()">
          </el-input>

          <el-input v-else
            type="text"
            oninput="this.value = this.value.replace(/[^\da-z]/gi, '')"
            ref="inputField"
            name="otpcode"
            class="form-control with-btn"
            v-model="codeInput"
            :maxlength="getMaxLength"
            @focus="focusInput()"
            @blur="blurInput()"
            @input="handleInput()">
          </el-input>

        </div>
      </div>
    </form>
  </div>
</template>

<script>
  
  export default {
    
    props: {
      maxLength: {
        type: Number,
        default: 0,
      },
      triggerOnMaxLength: {
        type: Boolean,
        default: false
      },
      digitsOnly: {
        type: Boolean,
        default: false,
      },
      submitable: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        plh: this.$i18n.t('controls.sms.plh'),
      }
    },
    computed: {
      codeInput: {
        get() {
          return this.$store.getters.GET_CODE;
        },
        set(v) {
          this.$store.commit('MUTATE_CODE', v);
        }
      },
      isActive() {
        return this.codeInput != null && !!this.codeInput.length;
      },
      getMaxLength() {
        return this.maxLength ? this.maxLength : null;
      }
    },
    methods: {
      focusInput() {
        this.$store.dispatch('SET_ANIMATE_BUTTON', true);
        this.$refs.controlRow.classList.add('active');
        this.$refs.formItem.classList.remove('is-error');
      },
      blurInput() {
        this.$store.dispatch('SET_ANIMATE_BUTTON', false);
        if(!this.isActive) {
          this.$refs.controlRow.classList.remove('active');
          this.$refs.formItem.classList.add('is-error');
        } else if (!!this.maxLength && this.codeInput.length < this.maxLength) {
          this.$refs.formItem.classList.add('is-error');
        }
      },
      handleInput() {
        if (!this.triggerOnMaxLength || !this.maxLength) return;

        if (this.isActive && this.codeInput.length === this.maxLength) {
          this.$store.dispatch('SET_CODE', this.codeInput);
        }    
      },
      handleSubmit() {
        if (!this.submitable) return;

        if (this.isActive) {
          this.$store.dispatch('SET_CODE', this.codeInput);
          this.blurInput();
        }    
      },
    },
    mounted() {
      this.$refs.inputField.focus();
      this.codeInput = this.$store.getters.GET_CODE;
      if(this.isActive) {
        this.focusInput();
      }
    },
  }

</script>