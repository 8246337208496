<template>
  <div>
    <div class="form-row"
      :class="{'active': selectedClass}"
      ref="controlRow">
      <div class="select-box">
        <div class="form-control-plh">
          {{plh}}
        </div>
        <el-select 
          v-model="selectedValue"
          placeholder=""
          :popper-append-to-body="false">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    
    <sms-type @resendSmsmCode="sendSmsCode" v-if="smsTypeSelectedPhone"/>
  </div>
</template>

<script>
  import SmsType from '../form-controls/SmsType.vue';
  import Api from '../../services/api.js';

  export default {
    components: {
      SmsType
    },
    data() {
      return {
        value: null,
        plh: this.$i18n.t('validation.sms'),
        options: [],
        sendedCodeLength: 4,
      }
    },
    methods: {
      sendSmsCode() {
        Api.postSms(this.selectedValue).then((response) => {
            setTimeout(() => {
              this.$store.commit('MUTATE_SHOW_RESEND_CODE', true);
            }, 60000);
          return response;
        });
      }
    },
    computed: {
      selectedValue: {
        get() {
          return this.$store.getters.GET_AUTH_TYPE_SELECTED_VALUE;
        },
        set(v) {
          this.$store.commit('MUTATE_AUTH_TYPE_SELECTED', v);
          this.$store.commit('MUTATE_CODE', null);
          this.$refs.controlRow.classList.add('active');
          this.sendSmsCode();
        }
      },
      smsTypeSelectedPhone() {
        return !!this.$store.getters.GET_AUTH_TYPE_SELECTED_VALUE;
      },
      selectedClass() {
        return this.selectedValue;
      },
    },
    mounted() {
      this.$store.commit('MUTATE_CODE_VALIDATION_LENGTH', this.sendedCodeLength);
      Api.getSmsTypes().then((response) => {
        this.options = response.data;
        if (this.options.length === 1) {
          this.selectedValue = this.options[0].value;
        }
      });
      if(this.selectedValue) {
        this.$refs.controlRow.classList.add('active');
      } else {
        this.$refs.controlRow.classList.remove('active');
      }
    },
    destroyed() {
      this.$store.commit('MUTATE_CODE_VALIDATION_LENGTH', 6);
      this.$store.commit('MUTATE_SHOW_RESEND_CODE', false);
    }
  }
</script>