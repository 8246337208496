<template>
  <div class="modal offset-bottom">
    <div class="holder">
      <div class="content">
        <p class="modal-text">{{ $t('modals.accept.title') }}</p>
        <div class="btn-box">
          <a
            href="#"
            class="link"
            @click.stop.prevent="closeModal()"
          >{{ $t('modals.accept.btn') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { eventBus } from '../../event-bus.js';

  export default {
    data() {
      return {
        modalOpen: false,
      }
    },
    mounted() {
     eventBus.$on('modal-open',(modalOpen)=>{
        this.modalOpen = modalOpen;
      });
    },
    methods: {
      closeModal() {
        this.modalOpen = false;
        eventBus.$emit('modal-open',this.modalOpen);
      }
    }
  }
</script>

<style scope lang="scss">
  .btn-box {
    .link {
      text-decoration: none;
      &:hover {
        text-decoration: none;
        color: #31628e;
      }
    }
  }
</style>