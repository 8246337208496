import Vue from "vue";
import VueI18n from "vue-i18n";
import { DEFAULT_LANGUAGE } from '../src/config.js';

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "../lang",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function convertToShortLang(lang) {
  // let lang = window.navigator.languages ? window.navigator.languages[0] : null;
  // lang = lang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;

  let shortLang = lang;
  if (shortLang.indexOf('-') !== -1)
      shortLang = shortLang.split('-')[0];

  if (shortLang.indexOf('_') !== -1)
      shortLang = shortLang.split('_')[0];

  return shortLang;
}

const languages = Object.keys(loadLocaleMessages());
let shortLang = convertToShortLang(navigator.language);
if (!languages.includes(shortLang)) {
  shortLang = DEFAULT_LANGUAGE;
}

export default new VueI18n({
  locale: shortLang,
  messages: loadLocaleMessages(),
});
