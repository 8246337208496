<template>
  <div id="app">
    <div class="main-container">
      <Header :logo="logo" />

      <main id="main">
          <div
            :swicher="!modalOpen"
            class="page-holder"
            :settings="settings"
          >
          <transition
            v-if="slideLeft"
            name="slide">
              <router-view class="routes"></router-view>
          </transition>
          <transition
            v-else
            name="slide-left">
              <router-view class="routes"></router-view>
          </transition>
          </div>
      </main>

      <Footer/>
    </div>
  </div>
</template>

<script>
import Footer from './components/Footer.vue';
import Header from './components/Header.vue';
import { REQUEST_ID } from './config.js';
import { eventBus } from './event-bus.js';

export default {
  components: {
    Footer,
    Header
  },
  data() {
    return {
      modalOpen: false,
      slideLeft: true,
      settings: {
        maxScrollbarLength: 60,
        suppressScrollX: false,
      },
    }
  },
  computed: {
    logo() {
      return this.$store.getters.GET_LOGO;
    },
    locale() {
      return this.$store.getters.GET_CURR_LOCALE;
    }
  },
  methods: {
    setRoute() {
      this.$store.commit('MUTATE_CURR_LOCALE', this.$i18n.locale);
    }
  },
  watch: {
    $route(to, from) {
      this.setRoute();
      this.slideLeft = from.name === 'Home';
      return this.slideLeft;
    },
    locale() {
      REQUEST_ID && this.$store.dispatch('SET_LOGO');
    }
  },
  mounted() {
    this.setRoute();
    eventBus.$on('modal-open',(modalOpen)=>{
      this.modalOpen = modalOpen;
    });

    // select fix for ios
    if(!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)) {
      document.addEventListener('touchstart', function(event) {
        if(event.target.closest('.el-select-dropdown__item') !== null ){
          event.target.click();
        }
      })
    }
  }
}
</script>
