
<template>
  <div class="btn-holder center">
    <span class="animation-button">
      <img v-if="active" src="@/assets/images/button.gif" alt="btn">
      <img v-else src="@/assets/images/button.png" alt="btn">
    </span>
  </div>
</template>

<script>
  export default {
    props: ['active'],
    data() {
      return {
        
      }
    }
  } 
</script>

<style>

</style>