<template>
  <div>
    <box-head :titleText="title" :showDropdown="false" :showBackArrow="true" :descriptionText="description"
      :goHome="false" @back="$router.go(-1)" />

    <div class="box-content">
      <ul>
        <li>
          {{ contacts.name }}
        </li>
        <li>
          <a class="link" :href="'mailto:' + contacts.email">
            {{ contacts.email }}
          </a>
        </li>
        <li v-if="contacts.displayPhone">
          <a class="link" :href="`tel:+${contacts.phone}`">
            {{ contacts.phone | phone }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import BoxHead from '../components/BoxHead.vue';
import Api from '../services/api.js';

export default {
  components: {
    BoxHead,
  },
  data() {
    return {
      title: this.$i18n.t('contactsPage.title'),
      description: this.$i18n.t('contactsPage.description'),
      contacts: {
        name: "",
        phone: "",
        displayPhone: false,
        email: ""
      }
    }
  },
  mounted() {
    Api.getContacts().then((response) => {
      this.contacts = response.data;
    });
  },
  computed: {
    hiddenFormShow() {
      return this.$store.getters.GET_HIDDEN_FORM_SHOW;
    },
  }
}
</script>

<style scoped lang="scss">
ul {
  display: block;
  padding: 15px 0 0;
  margin: 0;
  list-style: none;

  li {
    font-weight: 600;
    margin-bottom: 18px;
    line-height: 20.25px;
  }

  a {
    font-family: $font2;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.02em;
    line-height: 20.25px;

    span {
      font-family: $font2;
      font-weight: 600;
    }
  }
}
</style>