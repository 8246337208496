import axios from 'axios';
import store from '../store/index.js';

import {
  AUTH_PATH,
  VALIDATION_TYPE_TELEGRAM,
  VALIDATION_TYPE_WEBAUTHN,
  VALIDATION_TYPE_SMS,
  VALIDATION_TYPE_APP,
  assertRequestId
 } from '../config.js';

import router from './router.js';

export default {
  getLogo() {
    redirectIfIdAssertionFailed();
    return axios.get(`${AUTH_PATH}logo`, {});
  },
  
  getAuthTypes() {
    redirectIfIdAssertionFailed();
    return axios.get(`${AUTH_PATH}providers?lang=${store.getters.GET_CURR_LOCALE}`, {});
  },

  // Telegram
  getTelegramTypes() {
    redirectIfIdAssertionFailed();
    return axios.get(`${AUTH_PATH}${VALIDATION_TYPE_TELEGRAM}/items?lang=${store.getters.GET_CURR_LOCALE}`, {});
  },

  // WebAuth
  getWebAuthTypes() {
    redirectIfIdAssertionFailed();
    return axios.get(`${AUTH_PATH}${VALIDATION_TYPE_WEBAUTHN}/items?lang=${store.getters.GET_CURR_LOCALE}`, {});
  },

  getWebAuthResponse() {
    redirectIfIdAssertionFailed();
    return axios.get(`${AUTH_PATH}webauthn?lang=${store.getters.GET_CURR_LOCALE}`, {});
  },

  // SMS
  getSmsTypes() {
    redirectIfIdAssertionFailed();
    return axios.get(`${AUTH_PATH}${VALIDATION_TYPE_SMS}/items?lang=${store.getters.GET_CURR_LOCALE}`, {});
  },

  postSms(id) {
    redirectIfIdAssertionFailed();
    return axios.post(`${AUTH_PATH}sms?id=${id}`, {});
  },

  // APP
  getAppTypes() {
    redirectIfIdAssertionFailed();
    return axios.get(`${AUTH_PATH}${VALIDATION_TYPE_APP}/items?lang=${store.getters.GET_CURR_LOCALE}`, {});
  },

  // Contacts
  getContacts() {
    redirectIfIdAssertionFailed();
    return axios.get(`${AUTH_PATH}contact?lang=${store.getters.GET_CURR_LOCALE}`, {});
  }
}

function redirectIfIdAssertionFailed() {
  try {
    assertRequestId();
  }
  catch (e) {
    console.error(e);
    router.push({name: 'EmptyRequest'});
  }
}