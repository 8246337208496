<template>
  <form
    ref="hiddenForm"
    :method="formMethod"
    :action="formAction"
    :target="formTarget">
    <fieldset>
      <input type="hidden" name="accessToken" :value="formInputValue" />
    </fieldset>
  </form>
</template>

<script>
  import {eventBus, EMIT_SEND_HIDDEN_FORM} from '../event-bus.js';
  export default {
    computed: {
      formMethod() {
        return this.$store.getters.GET_HIDDEN_FORM_METHOD;
      },
      formAction() {
        return this.$store.getters.GET_HIDDEN_FORM_ACTION;
      },
      formTarget() {
        return this.$store.getters.GET_HIDDEN_FORM_TARGET;
      },
      formInputValue() {
        return this.$store.getters.GET_HIDDEN_FORM_INPUT_VALUE;
      },
    },
    methods: {
      submit() {
        this.$refs.hiddenForm.submit();
      },
    },
    mounted() {
      eventBus.$on(EMIT_SEND_HIDDEN_FORM,()=>{
        this.submit();
      });
    },
    destroyed() {
      eventBus.$off(EMIT_SEND_HIDDEN_FORM);
    }
  }
</script>

<style scoped lang="scss">
  form {
    position: absolute;
    top: -999999px;
    left: -999999px;
    opacity: 0;
    pointer-events:none;
  }
</style>