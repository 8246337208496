<template>
  <header class="header">
    <h1>
      <img :src="logo" alt="ico" v-show="logo">
    </h1>
  </header>
</template>

<script>
  export default {
    props: {
      logo: {
        type: String,
        //default: require('@/assets/images/logo.svg'),
      }
    }
  }
</script>

<style scoped>
.header {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0 10px 0;
}

</style>