<template>
  <div class="form-row" :class="{'active': selectedClass}" ref="controlRow">
    <div class="select-box">
      <div class="form-control-plh">
        {{plh}}
      </div>
      <el-select 
        v-model="selectedValue"
        placeholder=""
        :popper-append-to-body="false">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
  import Api from '../../services/api.js';
  import { eventBus, EMIT_NO_AUTH_PROVIDERS } from '../../event-bus.js';

  export default {
    data() {
      return {
        options: [],
        plh: this.$i18n.t('validation.main')
      }
    },
    computed: {
      selectedValue: {
        get() {
          return this.$store.getters.GET_AUTH_TYPE && this.$store.getters.GET_AUTH_TYPE.value || null;
        },
        set(v) {
          const selected = this.options.find(f => f.value === v);
          if (selected) {
            this.$store.commit('MUTATE_AUTH_TYPE', selected);
            this.$store.commit('MUTATE_AUTH_TYPE_SELECTED', null);
            this.$refs.controlRow.classList.add('active');
          }
        }
      },
      selectedClass() {
        return this.selectedValue;
      },
    },
    mounted() {
      Api.getAuthTypes().then((response) => {
        this.options = response.data;
        if (this.options.length) {
          this.selectedValue = this.options[0].value;
        } else {
          eventBus.$emit(EMIT_NO_AUTH_PROVIDERS);
        }
      });
      if(this.selectedValue) {
        this.$refs.controlRow.classList.add('active');
      } else {
        this.$refs.controlRow.classList.remove('active');
      }
    },
  }
</script>