export function formatPhoneNumber(phone)
{
    if (!phone) return '';  
    if (phone.length < 8) return phone;
    
    let postfix = '';
    const asterisk = phone.indexOf('*');
    if (asterisk !== -1) {
        if (asterisk === 0) return phone;
        
        postfix = phone.substr(asterisk);
        phone = phone.substr(0, asterisk);
    }
    
    const n = parseInt(phone);
    return `+${format(n.toString(), '# ### ###-####')}${postfix}`; 
}

function format(value, pattern) {
    let i = 0;
    const v = value.toString();
    return pattern.replace(/#/g, _ => v[i++] || '');
}