<template>
  <div>
    <OtpInput :maxLength="6" triggerOnMaxLength digitsOnly />
  </div>
</template>

<script>
  import OtpInput from '../form-controls/OtpInput.vue'

  export default {
    components: {
      OtpInput
    },
  }

</script>