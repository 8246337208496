<template>
  <div>
    <box-head
      :titleText="title"
      :showDropdown="false"
      :showExclamationMark="true"
      :descriptionText="description"
    />
    <div class="box-content">
      <ul>
        <li>
          {{contacts.name}}
        </li>
        <li>
          <a class="link" :href="'mailto:'+contacts.email">
            {{contacts.email}}
          </a>
        </li>
        <li v-if="contacts.displayPhone">
          <a class="link" :href="`tel:+${contacts.phone}`">
            {{contacts.phone | phone}}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import BoxHead from '../components/BoxHead.vue';
  import Api from '../services/api.js';
  export default {
    components: {
      BoxHead
    },
    data() {
      return {
        title: this.$i18n.t('errorPage.title'),
        description: this.$i18n.t('errorPage.description'),
        contacts: {
          name: "",
          phone: "",
          displayPhone: false,
          email: ""
        }
      }
    },
    mounted() {
      Api.getContacts().then((response) => {
        this.contacts = response.data;
      });
      if(this.$route.params.errCode) {
        this.title = this.$i18n.t(`errors.${this.$route.params.errCode}.title`);
        this.description = this.$i18n.t(`errors.${this.$route.params.errCode}.description`);
      }
    }
  }
</script>

<style scoped lang="scss">
  ul {
    display: block;
    padding: 15px 0 0;
    margin: 0;
    list-style:none;
    li {
      font-weight: 600;
      margin-bottom: 18px;
      line-height: 20.25px;
    }
    a {
      font-family: $font2;
      font-weight: 600;
      font-size: 15px;
      letter-spacing: 0.02em;
      line-height: 20.25px;
      span {
        font-family: $font2;
        font-weight: 600;
      }
    }
  }
</style>