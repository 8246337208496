<template>
  <div>
    <OtpInput submitable />
  </div>
</template>

<script>

  import OtpInput from '../form-controls/OtpInput.vue';

  export default {
    components: {
      OtpInput
    },
  }

</script>