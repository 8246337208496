<template>
  <div>
    <div class="form-row" :class="{'active': selectedClass}" ref="controlRow">
      <div class="select-box">
        <div class="form-control-plh">
          {{plh}}
        </div>
        <el-select 
          v-model="selectedValue"
          placeholder=""
          :popper-append-to-body="false">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <div v-if="shouldDisplayUpdateWarning" class="flex-container">
      <div class="alert" role="alert">
        {{ warningMessage }}
      </div>
    </div>
    <div v-else class="flex-container">
      <div style="font-size: 3rem">
        {{ mobilePushOtpCode }}
      </div>
    </div>
  </div>
</template>

<script>
  import Api from '../../services/api.js';

  export default {
    data() {
      return {
        plh: this.$i18n.t('validation.mob'),
        options: [],
        disabled: false
      }
    },
    computed: {
      selectedValue: {
        get() {
          return this.$store.getters.GET_AUTH_TYPE_SELECTED_VALUE;
        },
        set(v) {
          this.mobilePushOtpCode = this.options.find(s => s.value === v)?.mobilePushOtpCode;
          this.shouldDisplayUpdateWarning = this.options.find(s => s.value === v)?.shouldDisplayUpdateWarning ?? false;
          this.$store.dispatch('SET_ANIMATE_BUTTON', true);
          this.$store.commit('MUTATE_AUTH_TYPE_SELECTED', v);
          this.$refs.controlRow.classList.add('active');
          this.$store.dispatch('SEND_TOKEN');
        }
      },
      selectedClass() {
        return this.selectedValue;
      },
      mobilePushOtpCode: {
        get() {
          return this.$store.getters.GET_MOBILE_PUSH_OTP_CODE;
        },
        set(v) {
          this.$store.commit('MUTATE_MOBILE_PUSH_OTP_CODE', v);
        }
      },
      shouldDisplayUpdateWarning: {
        get() {
          return this.$store.getters.GET_SHOULD_DISPLAY_UPDATE_WARNING;
        },
        set(v) {
          this.$store.commit('MUTATE_SHOULD_DISPLAY_UPDATE_WARNING', v);
        }
      },
      warningMessage: {
        get() {
          return this.$i18n.t('warnings.updateMobileDevice');
        }
      }
    },
    mounted() {
      Api.getAppTypes().then((response) => {
        this.options = response.data;
        if (this.options.length === 1) {
          this.selectedValue = this.options[0].value;
          this.mobilePushOtpCode = this.options[0].mobilePushOtpCode;
        }
      });
    },
    destroyed() {
      this.$store.dispatch('SET_ANIMATE_BUTTON', false);
    }
  }
</script>