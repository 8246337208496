<template>
  <div>
    <box-head :titleText="title" :showDropdown="false" :showExclamationMark="true" :descriptionText="description" />
  </div>
</template>

<script>
import BoxHead from '../components/BoxHead.vue';
export default {
  components: {
    BoxHead
  },
  data() {
    return {
      title: this.$i18n.t('emptyRequestPage.title'),
      description: this.$i18n.t('emptyRequestPage.description'),
    }
  },
}
</script>